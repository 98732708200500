import { useLocation } from '@remix-run/react';
import { useTrackIdentity, useTrackRoute } from './tracking';

export default function RouteTracker({ userId }: { userId: string | null }) {
  const location = useLocation();

  useTrackIdentity(userId);
  useTrackRoute(location.pathname);

  return null;
}
